export type DeviceProps = {
  name: string;
  id: string;
};

export const nutrientControllers: DeviceProps[] = [
  { name: "1번 양액 제어기", id: "NAB300-0006" },
];

export const cultivators: DeviceProps[] = [
  { name: "1번 재배기", id: "OA100-0028" },
  { name: "2번 재배기", id: "OA100-0029" },
  { name: "3번 재배기", id: "OA100-0030" },
];

export const envs: DeviceProps[] = [
  { name: "1번 환경 측정기", id: "ENV100-0028" },
];

export const acs: DeviceProps[] = [
  { name: "1번 에어컨 제어기", id: "AC100-0005" },
];

export const smartFarmID = 5;
