import React, { useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import SideBar from "../components/dashboard/SideBar";
import { isUserInfoExist } from "../lib/checkUserInfo";
import DeviceStatusComponent from "../components/dashboard/DeviceStatusComponent";
import NutrientControllerComponent from "../components/dashboard/NutrientControllerComponent";
import CultivatorComponent from "../components/dashboard/CultivatorComponent";
import AirConditionerComponent from "../components/dashboard/AirConditionerComponent";
import ChartGroup from "../components/dashboard/ChartGroup";
import * as deviceIDs from "../data/deviceIDs";

const DashboardPageBlock = styled.div`
  width: 100%;
  height: 100%;
`;

const Contents = styled.div`
  width: 100%;
  height: 94%;
  display: flex;
  flex-direction: row;
  padding: 0.5rem 0 0.5rem 0;
  @media ${(props) => props.theme.mobile} {
    height: 170%;
    flex-direction: column;
  }
`;

const LeftHalfDiv = styled.div`
  width: 50%;
  height: 100%;
  padding: 0 0 0 0.5rem;
  display: flex;
  flex-direction: column;

  @media ${(props) => props.theme.mobile} {
    width: 100%;
    padding: 0;
    border-right: none;
    border-bottom: solid;
    border-width: 0.5px;
  }
`;

const LeftDiv = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: row;

  @media ${(props) => props.theme.mobile} {
    height: 100%;
    flex-direction: column;
  }
`;

const RightHalfDiv = styled.div`
  width: 50%;
  height: 100%;
  padding: 0 0.5rem 0 0;

  @media ${(props) => props.theme.mobile} {
    width: 100%;
    padding: 0;
  }
`;

function DashboardPage() {
  const history = useHistory();

  useEffect(() => {
    let system = navigator.platform;

    if (system !== "Linux armv7l") {
      if (isUserInfoExist(sessionStorage) === -1) {
        alert("로그인이 필요합니다 !");
        history.push("/");
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <DashboardPageBlock>
      <SideBar url="/dashboard" />
      <Contents>
        <LeftHalfDiv>
          <LeftDiv>
            <DeviceStatusComponent
              deviceIDs={deviceIDs.nutrientControllers
                .concat(deviceIDs.cultivators)
                .concat(deviceIDs.envs)
                .concat(deviceIDs.acs)}
            />
            <NutrientControllerComponent
              deviceIDs={deviceIDs.nutrientControllers}
            />
          </LeftDiv>
          <LeftDiv>
            <CultivatorComponent deviceIDs={deviceIDs.cultivators} />
            <AirConditionerComponent deviceIDs={deviceIDs.acs} />
          </LeftDiv>
        </LeftHalfDiv>
        <RightHalfDiv>
          <ChartGroup smartFarmID={deviceIDs.smartFarmID} />
        </RightHalfDiv>
      </Contents>
    </DashboardPageBlock>
  );
}

export default DashboardPage;
